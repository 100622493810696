import React from 'react';

import { Skeleton } from '@mui/material';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import MultiSelect from '@fd/ui/Select/Select';

type Props = {
  isLoading: boolean;
  options: Array<{ label?: string; value?: number; scId?: string }>;
};
const SalesChannelGroupSelector = ({ isLoading, options }: Props) => {
  return (
    <FormItemLayout label={<Translate id="Sales_channels" />}>
      <Field name="salesChannels">
        {({ field, form }: FieldProps) => {
          return isLoading ? (
            <div style={{ paddingRight: 16 }} data-fd="salesChannels-loader">
              <Skeleton variant="rounded" width={'100%'} height={44} />
            </div>
          ) : (
            <MultiSelect
              dataFd="sales-channels-select"
              isClearable={false}
              isDisabled={form.isSubmitting}
              isMulti
              maxChips={5}
              value={field.value}
              options={options}
              variant="standard"
              TextFieldProps={{
                style: { paddingRight: 16 },
              }}
              onChange={(options: Props['options']) => {
                form.setFieldValue(field.name, options);
              }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

export default SalesChannelGroupSelector;
