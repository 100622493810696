import React from 'react';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { type FormikProps, Form, withFormik } from 'formik';
import type { StoreGroupExtended } from 'overrides/@flipdish/api-client-typescript/api';
import { Translate } from 'react-localize-redux';

import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LoadingButton from '@fd/ui/LoadingButton';

import CurrencySelector from './CurrencySelector';
import SalesChannelGroupNameField from './SalesChannelGroupNameField';
import SalesChannelGroupSelector from './SalesChannelGroupSelector';

const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingRight: 0,
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const StyledFieldItem = styled(Grid)(() => ({
  borderBottom: '1px solid rgba(0,0,0,0.2)',
}));

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-end',
  alignContent: 'center',
  padding: theme.spacing(1),
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export type FormValues = {
  currency: {
    label?: StoreGroupExtended.CurrencyEnum;
    value?: StoreGroupExtended.CurrencyEnum;
  };
  name: string;
  salesChannels: Array<{ label: string; value: number; scId: string; storeGroupId: number }>;
};

export type SalesChannelGroupFormProps = {
  isSalesChannelsLoading: boolean;
  options: Array<{ label?: string; value?: number; scId?: string }>;
  onSubmit: (values: FormValues) => Promise<void>;
} & FormValues;

const SalesChannelGroupForm = ({
  isSubmitting,
  handleSubmit,
  isSalesChannelsLoading,
  options,
}: SalesChannelGroupFormProps & FormikProps<FormValues>) => {
  return (
    <Form>
      <PaperContainer fluid>
        <StyledContainer container>
          <StyledFieldItem item xs={12}>
            <SalesChannelGroupNameField />
          </StyledFieldItem>
          <StyledFieldItem item xs={12}>
            <SalesChannelGroupSelector isLoading={isSalesChannelsLoading} options={options} />
          </StyledFieldItem>
          <StyledFieldItem item xs={12}>
            <CurrencySelector />
          </StyledFieldItem>
          <StyledButtonContainer container>
            <StyledLoadingButton
              fdKey="Add_SmsRestaurantName"
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
            >
              <Translate id={'Save'} />
            </StyledLoadingButton>
          </StyledButtonContainer>
        </StyledContainer>
      </PaperContainer>
    </Form>
  );
};

export default withFormik<SalesChannelGroupFormProps, FormValues>({
  displayName: 'SalesChannelGroupForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return props;
  },
  handleSubmit: async (values, { props: { onSubmit }, setSubmitting }) => {
    try {
      await onSubmit(values);
    } catch (e) {
      setSubmitting(false);
    }
  },
})(SalesChannelGroupForm);
