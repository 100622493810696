import type { RestApiPaginationResultStoreGroupExtended } from 'overrides/@flipdish/api-client-typescript/api';

import type { StoreHeadersEnhanced } from '../SalesChannelGroupDetails';

export function getUpdatedStoreGroupData({
  data,
  currentStoreGroupId,
  selectedChips,
}: {
  data?: RestApiPaginationResultStoreGroupExtended;
  currentStoreGroupId: number;
  selectedChips: StoreHeadersEnhanced;
}) {
  const newData: RestApiPaginationResultStoreGroupExtended = JSON.parse(JSON.stringify(data));

  selectedChips.forEach((chip) => {
    const { StoreId: storeId, fromStoreGroupId: fromGroupId } = chip;

    const fromGroup = newData?.Data.find((group) => group.StoreGroupId === fromGroupId);
    const toGroup = newData?.Data.find((group) => group.StoreGroupId === currentStoreGroupId);

    if (fromGroup && toGroup) {
      const storeToMove = fromGroup?.StoreHeaders?.find((store) => store.StoreId === storeId);

      if (storeToMove && fromGroup.TotalStores !== undefined && toGroup.TotalStores !== undefined) {
        fromGroup.StoreHeaders = fromGroup?.StoreHeaders?.filter(
          (store) => store.StoreId !== storeId
        );
        fromGroup.TotalStores -= 1;

        toGroup.StoreHeaders?.push(storeToMove);
        toGroup.TotalStores += 1;
      }
    }
  });

  return newData;
}
