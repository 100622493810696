import {
  type GetSalesChannelsByOrgIdAndPropertyId200Response,
  Configuration,
  SalesChannelsApi,
} from '@flipdish/orgmanagement';

import { getApiEndPoint } from '../../../helpers/apibase';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const salesChannelsApi = new SalesChannelsApi(configuration);

export const getSalesChannelsByOrgAndPropertyIdKey = 'getSalesChannelsByOrgAndPropertyId';
export async function getSalesChannelsByOrgAndPropertyId(
  orgId: string,
  propertyId: string
): Promise<{ data: GetSalesChannelsByOrgIdAndPropertyId200Response }> {
  try {
    return await salesChannelsApi.getSalesChannelsByOrgIdAndPropertyId(
      { orgId, propertyId },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const brandService = { getSalesChannelsByOrgAndPropertyId };
